@charset "UTF-8";
@import "~bootstrap/dist/css/bootstrap.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

/* CSS Document */
/* COMMON CSS
==================================================================================================== */
:root {
  --primary-colour: #A9B533;
  --secondary-colour: #143A4D;
  --primary-text-colour: #213446;
  --secondary-text-colour: #1D2939;
  --title-color: #1A1A1A;
  --label-color: #334155;
  --grid-head-color: #143A4D;
  --grid-body-color: #5E6D83;
  --grid-background-footer-color: #F2F5F6;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-text-colour);
}

/*Login cover page */
.bg-cover {
  min-height: 100vh;
  /* background-image: url('assets/images/cover-bg-1.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Sign in */
.sign-in {
  height: 100vh;
  background: #4D5153;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in .password-icon {
  padding: absolute;
  position: absolute;
  right: 12px;
  top: 7px;
  cursor: pointer;
}

/* ------------------------------------------------------ */

/* sidebar */
#sidebar {
  background-color: var(--secondary-colour);
  /* background-color:#143A4D; */
  min-width: 250px;
  max-width: 250px;
  padding: 16px 8px;
  min-height: 100vh;
  height: 100vh;
  /* position: relative; */
  transition: all 0.02s;
  position: fixed;
  z-index: 10;
  /* z-index: 1052; */
}

#sidebar .nav-pills .nav-link {
  color: #FFF;
  padding: 12px 16px;
}

#sidebar .nav-pills .nav-link:hover,
#sidebar .nav-pills .nav-link.active {
  background-color: var(--primary-colour);
}

#sidebar .sidebar-header strong {
  display: none;
}


/* sidebar collapsed */
#sidebar.collapsed {
  min-width: 70px;
  max-width: 70px;
}

#sidebar .menu .nav-link span {
  display: inline-block;
  padding-left: 8px;
}

#sidebar.collapsed .menu .nav-link span {
  display: none;
}

#sidebar.collapsed footer,
#sidebar.collapsed .sidebar-header h3 {
  display: none;

}

#sidebar.collapsed .sidebar-header strong {
  display: block;
}

#sidebar .toggle-sidebar {
  position: absolute;
  top: 16px;
  right: -16px;
  width: 32px;
  height: 32px;
  background-color: #FFF;
  border-radius: 7px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#sidebar .toggle-sidebar:hover {
  border: 1px solid var(--primary-colour);
}

#sidebar .toggle-sidebar img {
  transform: rotate(180deg);
  transition: all 0.3s;
}

#sidebar.collapsed .toggle-sidebar img {
  transform: rotate(0deg);
}

/* header */
header.header {
  position: fixed;
  padding: 24px;
  width: calc(100% - 250px);
  left: 250px;
  transition: all 0.03s;
  background-color: #F0F2F3;
  /* z-index: 1051; */
  z-index: 9
    /*for sidebar*/
    /* z-index: 9999; */
}

/* dropdown z-index */
/* .multiselect-dropdown .dropdown-list{
  z-index:1050;
} */

header.header.collapsed {
  position: fixed;
  padding: 24px;
  width: calc(100% - 70px);
  left: 70px;
}

/* Main-content-Area */
.wrapper .main-content {
  background-color: #F0F2F3;
  padding-left: 94px !important;
  transition: all 0.03s;
  /* height setting */
  /* min-height: 100vh; */
  height: 99vh;
  overflow: auto;
  position: relative;
  /* top: 72px; */
  padding-top: 72px !important;
}

.wrapper .main-content.sidebar-open {
  padding-left: 274px !important;
}

/* tab */
.tab .nav.nav-pills .nav-link {
  background-color: #F9FAFB;
  color: var(--primary-text-colour);

  padding: 10px 16px;
}

.tab .nav.nav-pills .nav-link.active {
  background-color: var(--secondary-colour) !important;
  color: #FFF;
  font-weight: 600;
}

.tab .nav.nav-pills .nav-item:first-child .nav-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab .nav.nav-pills .nav-item:nth-child(2) .nav-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* my letter accordian*/
.my-letter .accordion-button:not(.collapsed) {
  background-color: #fff;
}

/* .my-letter .search-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
} */

.table .dropdown-toggle::after {
  display: none;
}

/* dropdown after icon removing */
.dropdown .dropdown-toggle::after {
  display: none;
}

/* Tool Tip */
#sidebar #menu>li {
  position: relative;
}

.sidebar #menu>li .tool-tip {
  display: none;

}

.sidebar.collapsed #menu>li:hover .tool-tip {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 0.25rem;
  background-color: var(--secondary-colour);
  padding: 0.25rem 1rem;
  min-width: 100px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tool-tip::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%) rotate(90deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: var(--primary-colour) transparent transparent transparent;
}

/* table */
.engagedash-table table tbody tr td:nth-child(7),
.engagedash-table table tbody tr td:nth-child(8),
.engagedash-table table tbody tr td:nth-child(9) {
  word-break: break-all;
}

/* dropdown -chips customization */


.multiselect-dropdown .ng-star-inserted {
  color: #99a5b5;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  border: 2px solid #8F9FBC !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  background-color: #8F9FBC !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  background-color: #E2E8F0 !important;
  border: 1px solid #CBD5E1 !important;
  color: #334155 !important;
  border-radius: 12px !important;
  margin: 1px;
  padding: 0px 8px !important;
  /* font-size: 16px; */
}

.multiselect-dropdown .dropdown-btn .selected-item>a {
  background: #334155;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 4px !important;
  font-size: 13px;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  /* border-bottom: none; */
  border-width: 0px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th>input,
.p-datatable-thead>tr>th input {
  width: 100%;
}

.p-datatable .p-datatable-thead tr:nth-child(2) th {
  padding-top: 3px;
}

/* add block modal field table */
.fieldtable .p-datatable-header {
  border: none;
  padding: 0;
}

.fieldtable .p-datatable-header .p-input-icon-left {
  display: inline-block;
  width: 100%;
}

.fieldtable .p-datatable-header .p-input-icon-left::after {
  content: "";
}

/* edit field pop up child entities */
.response_tag_wrapper {
  border: 1px solid #99a5b5;
  color: #5E6D83;
  border-radius: 4px;
  padding: 8px 8px 0 8px;
  max-height: 125px;
  overflow: auto;
}

.response_tag {
  background-color: #c6dbf1;
  border-radius: 34px;
  padding: 2px 12px;
  color: #426694;
  margin-bottom: 8px;
  display: inline-block;
  margin-left: 10px;
}

.response_tag a {
  margin-left: 10px;
}


/* common styles */

/*removing eyeicon in edge  */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.form-control,
.form-select {
  border: 1px solid #99a5b5 !important;
  color: #5E6D83 !important;
  box-shadow: none !important;
  padding: 6px 12px;
  font-size: 14px;
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #D4DA99 !important;
}

.form-control:disabled,
.form-control[readonly] {
  background: #EBEBEB;
}

/* ---------------Disabling multiselect dropdown----------------- */
.isreadonlymultiselect .multiselect-dropdown .dropdown-btn {
  background-color: #EBEBEB;
  /* You can add more custom styles here if needed */
}

.isreadonlymultiselect .multiselect-dropdown .dropdown-btn .dropdown-down {
  display: none;
}

/* -------------------------------------------------- */
/* placeholders */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  color: #A8B1BE !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  color: #A8B1BE !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
  color: #A8B1BE !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.historylog-icon {
  content: url('assets/images/historylog-icon.png');
}

.refresh-icon {
  content: url('assets/images/refresh-icon.png');
}

.upload-icon {
  content: url('assets/images/upload-icon.png');
}

.cancel-sign-icon {
  content: url('assets/images/cancel-signing-icon.png');
}
.sign-remainder-icon{
  content: url('assets/images/signing_remainder.png');
  width: 16px;
  height: 16px;
}
.delete-icon {
  content: url('assets/images/delete-icon.png');
}

.sign-remainder-icon {
  content: url('assets/images/signing_remainder.png');
  width: 16px;
  height: 16px;
}

.edit-feild-icon {
  content: url('assets/images/edit.png');
}

.resend-icon {
  content: url('assets/images/resend-icon.png');
}

.preview-icon {
  content: url('assets/images/preview-letter.png');
}

.download-grey-icon {
  content: url('assets/images/download-grey-icon.png');
}

.search-clear-icon {
  content: url('assets/images/key_clear.svg');
  width: 24px;
  height: 24px;
}


.edit-feild-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  /* Example for disabled state */

}

.edit-feild-icon.dark:not(.disabled):hover,
.delete-icon.dark:not(.disabled):hover {
  filter: brightness(0.5);
  cursor: pointer;
}

.excel-btn {
  background-color: var(--grid-background-footer-color);
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.excel-btn button {
  background-color: var(--grid-background-footer-color);
  cursor: pointer;
}

.excel-btn button img {
  width: 16px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  background-color: var(--grid-background-footer-color);
}


.p-datatable-scrollable-body>table>.p-datatable-tbody>tr>td {
  white-space: normal;
}

.p-datatable-scrollable-body {
  /* max-height: calc(100vh - 314px) !important; */
  max-height: calc(100vh - 418px) !important;
}

.p-datatable-scrollable-body {
  overflow-y: auto !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  border-width: 0px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th>input {
  width: 100%;
}

/* dropdown inside table */
.table-multiselect .p-multiselect {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #99a5b5;
  font-size: 16px;
  width: 100%;
}

.table-multiselect .p-multiselect:not(.p-disabled):hover {
  border: 1px solid #99a5b5;
}

.table-multiselect .p-multiselect-filter-container {
  order: 1;
}

.table-multiselect .header-container.ng-star-inserted {
  order: 2;
  cursor: pointer;
  margin-left: 8px;
  color: #6c757d;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-multiselect .header-container.ng-star-inserted:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

/* .p-checkbox.p-component {
  order: 1;
} */

.table-multiselect .p-multiselect-close {
  order: 3;
}


/* panel */
.p-multiselect-panel {
  min-height: 190px;
  max-width: 190px;
}

.p-multiselect-panel .p-multiselect-filter-container input {
  padding-block: 4px;
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-items {
  font-size: 14px;
}

.table-multiselect.ng-dirty.ng-invalid>.p-multiselect {
  border-color: #98a5b5;
}

.table-multiselect .p-disabled,
.p-component:disabled {
  background: #EBEBEB;
  opacity: unset;
}

/* button */


.p-btn {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: var(--primary-colour);
  color: #FFF;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--primary-colour);
  font-weight: 500;
}

.p-btn:disabled,
.p-btn.disabled {
  background: #9F9F9F;
  border: 1px solid #9F9F9F;
  color: #FFF;
  font-weight: 500;
}

.btn.p-btn:focus {
  box-shadow: none;
}

.p-btn-outline {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: #FFF;
  color: var(--primary-colour);
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--primary-colour);
  font-weight: 500;
}

.p-btn-outline:disabled {
  border: 1px solid #9F9F9F;
  color: #9F9F9F;
  background-color: #FFF;
  font-weight: 500;
}

.btn.p-btn:not(:disabled):hover {
  background: #879129;
  color: #FFF;
}

.btn.p-btn-outline:not(:disabled):hover {
  color: #879129;
  border: 1px solid #879129;
}

a,
button,
li>a {
  cursor: pointer;
}

.point-cursor {
  cursor: pointer;
}

/* custom font size  */
.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.p-32 {
  padding: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

/* color class */
.highlight,
.title {
  color: var(--title-color);
}

.s-text {
  color: var(--secondary-colour) !important;
}

.modal-content {
  color: var(--primary-text-colour);
}

.err-color {
  color: #DE2F2F;
}

/*  Link color */
.link-color {
  color: #5E6D83;
}

.label-color,
.form-label {
  color: var(--label-color);
}

.form-check-label {
  color: #5E6D83;
}

/* radio */
.form-check-input:checked {
  background-color: #8F9FBC;
  border-color: #8F9FBC;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #8F9FBC;
}

/* width */
.w-30 {
  width: 30% !important;
}

/* Table */
.table thead tr th {
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  color: var(--grid-head-color);
  vertical-align: middle;
}

.table tbody tr td {
  color: var(--grid-body-color);
  vertical-align: middle;
  padding: 12px 16px;
}

.table thead,
.table tfoot {
  background-color: #F2F5F6;
  color: var(--secondary-colour);
}

table .status {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 8px;
}

table .status.draft {
  background-color: #F6C926;
}

table .status.inprogress {
  background-color: #0d6efd;
}

table .status.completed {
  background-color: #07AA4B;
}

/* P -table */
.p-datatable {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.p-datatable .p-sortable-column:focus,
.p-datatable .p-sortable-column:hover {
  box-shadow: none;
  color: var(--grid-head-color) !important;
}

/* .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--grid-head-color) !important;
} */

/* p -table paginator */
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #143a4d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
  font-weight: 700;
  font-size: 12px;
}

.p-paginator-pages button {
  font-size: 12px;
  font-family: 'open-sans';
}



/* preview section */
.preview-body {
  border-radius: 8px;
  border: 1px solid #E7EFFF;
  background: #F2F5F6;
  font-size: 16px;
  color: #013950;
}

.blockpreview {
  height: calc(100vh - 178px);
  /* overflow: auto; */
}

.blockpreview .preview-body {
  height: 94%;
  overflow: auto;
}

.blockpreview.panel-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  padding: 10px;
  max-height: 100vh;
  min-height: 100%;
}

.blockpreview.panel-fullscreen .preview-body {
  min-height: calc(100vh - 86px) !important;
  max-height: calc(100vh - 86px) !important;
}

/* ck editor */
/* #728994 */
.cke_inner.cke_reset>span {
  background-color: #728994;
}

/* .cke_ltr{
  filter: invert(1);
} */







.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #143a4d;
  margin-left: 0.5rem;
}


.p-paginator {
  justify-content: end !important;
  color: #143a4d !important;
}



.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #143a4d;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #99a5b5;
  min-height: 36px;
  align-items: center;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background: #EBEBEB;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}


/* In your component's CSS or global stylesheet */
/* ng prime menu inside the table */
.custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 16px;
  height: 16px;
  background-color: #FFF;
  color: var(--grid-body-color);
  border-radius: 50%;
  cursor: pointer;
}

.custom-menu .p-menu-overlay {
  position: fixed !important;
  display: block !important;
}

.custom-menu .p-menu {
  width: 12.5rem;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
}

.p-submenu-header {
  display: none;
}

.custom-menu .p-menuitem-link {
  padding: 10px 16px;
  font-size: 13px;
  color: var(--grid-body-color);
  gap: 8px;
}

/* .p-menu-overlay{
  position: fixed !important;
}  */

/* .custom-icon:hover {
  background-color: #788796;
} */

/* survey-template screen  */
/* .svd_container{
  height: 500px !important;
} */
/* .svd-designer-container--right-side{
  display: none;
} */


svd-splitter {
  display: table-cell;
  min-width: 4px !important;
  width: 4px !important;
  max-width: 4px !important;
  padding: 0 !important;
  cursor: ew-resize;
  position: relative;
}

svd-splitter div.svd-splitter {
  background-color: lightgray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.svd-designer-container--right-side {
  width: 30%;
}

.sv_row>div {
  flex-basis: 100% !important;
}

.sv_qstn question_actions sv_q--disabled svd_question svd-dark-bg-color svd_q_design_border

/* latest 23 Aug */
.svda-question-actions .svda_question_action:not(:nth-child(2)),
.svd_q_selected>.svda-question-actions .svda_action_separator {

  display: none;
}

.svda-question-actions .svda_question_action:nth-child(8) {
  display: inline-block;
}

.svd_container .icon-actiondragelement {
  top: 0;
}


/* All popup(Success,error,warning) styles */
.warning-modal {
  background: #F5A20D;
}

.error-modal {
  background: #E14D45;
}

.success-modal {
  background: #1BD477;
}

.errorcolor {
  color: #ff3434 !important;
  font-size: 100%;
}


.warning-modal .btn-close,
.error-modal .btn-close,
.success-modal .btn-close {
  background-image: url('assets/images/close-icon.png');
  background-size: 24px 24px;
  opacity: 1;
}

.warning-btn {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  color: #F5A20D;
  border: 1px solid #F5A20D;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-weight: 500;
}

.warning-btn:not(:disabled):hover {
  background-color: #F5A20D;
  color: #FFF;
}

.disabled-link {
  pointer-events: none;
  /* Disable click events */
  opacity: 0.5;
  /* Apply a lower opacity to indicate the link is disabled */
  cursor: not-allowed;
  /* Change the cursor to indicate it's not clickable */
  /* Add other styling as needed */
}

.error-btn {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  color: #E14D45;
  border: 1px solid #E14D45;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-weight: 500;
}

.error-btn:not(:disabled):hover {
  background-color: #E14D45;
  color: #FFF;
}

.success-btn {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  color: #1BD477;
  border: 1px solid #1BD477;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-weight: 500;
}

.success-btn:not(:disabled):hover {
  background-color: #1BD477;
  color: #FFF;
}

.shadow-popup {
  z-index: 1000;
}

/*Roles modal pop */
.custom-modalNorole {
  /*Places modal center of the page*/
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  padding: 0 !important;
}

.dialogModal {
  margin: 0;
}

/* p-checkbox customization */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-width: 1px;
  border-radius: 0;
  border-color: var(--primary-colour);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-width: 1px;
  border-radius: 0;
  /* box-shadow: 0 0 0 0.2rem #a6d5fa; */
  box-shadow: none;
  border-color: #ced4da;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-width: 1px;
  border-radius: 0;
  border-color: #ced4da;
  /* background-color: var(--primary-colour); */
  background-color: #FFF;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.pi-check:before {
  /* content: "\e909"; */
  content: " ";
  background: var(--primary-colour);
  display: inline-block;
  width: 10px;
  height: 10px;
}

/*pdf viewer page  */

/* .pdfViewer .page{
  border: none;
  box-shadow: 0px 0px 4px 4px #bbb;
  margin: auto;
} */
/* .pdfViewer .page{
  width: 100% !important;
  overflow: hidden !important;
} */
.pdfViewer .canvasWrapper {
  /* overflow: hidden; */
  margin-top: -6px;
  width: 100% !important;
}

.customtaxlayerremove .pdfViewer .textLayer {
  display: none;
}

.customtaxlayerremove .annotationLayer .textWidgetAnnotation {
  display: none;
}

.pdfViewer .textLayer {
  /* width: 100% !important; */
  display: block;
  margin: 0 auto;
  position: relative !important;
}

/*prime ng input fields */
.p-inputtext {
  font-family: 'Open Sans', sans-serif !important;
}

.p-inputtext:focus,
.p-inputtext:hover {
  border-color: #D4DA99 !important;
  box-shadow: none !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none !important;
  border-color: #D4DA99;
}

.isreadonlymultiselect {
  pointer-events: none;
}

#preview_doc .modal-dialog,
#editletter .modal-dialog {
  max-width: 920px;
}

/*removing undo button*/
.svd_container .svd_toolbar {
  display: none;
}

.listField .p-datatable-scrollable-body {
  max-height: 400px !important;
}

/* .modal-open{
  overflow: hidden;
} */


/* no data available */
.myPartner li>h5 {
  font-size: .95rem;
  padding: 1px 2px;
  margin: 0;
}

/* Style the container to position the button and hide the input  */
.file-input-container {
  position: relative;
  display: inline-block;
}

/* Style the hidden file input  */
.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  background: #F0F2F4;
  border: 1px dashed #C3C3C3;
}

/* history log */
.history-log .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
  word-wrap: break-word;
}

.history-log .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  text-align: center;
}

.disableDiv {
  opacity: 0.5;
  pointer-events: none;
}

/* multiselect dropdown  height issue*/
.multiselect-dropdown .dropdown-list {
  height: 290px;
  overflow: hidden;
}

.my-letter .ng-dropdown-panel,
.my-letter .ng-select.ng-select-opened .ng-select-container,
.multiselect-dropdown .dropdown-list {
  z-index: 7 !important;
  /* sidebar z index - 9 
   header z index - 8  */
}

.batch-info {
  width: 100%;
  background-color: #F0F2F3;
  border-spacing: 30px;
}

.batch-info td {
  width: 150px;
}




/* responsive  media queries */


@media (max-width: 1399.98px) {
  /* .my-letter .search-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  } */
}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
  /* .my-letter .search-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  } */
}

@media (max-width: 767.98px) {
  /* .my-letter .search-fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  } */

  #sidebar .toggle-sidebar {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .cover-page {
    position: relative;
  }

  .cover-page .right {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* survey */
.main_content.push-left {
  width: 100%;
  margin-left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main_content.push-left .header_body {
  width: 100%;
}

.main_content {
  margin-left: 200px;
  min-height: calc(100vh - 122px);
}

.main_content {
  margin-left: 0;
}

#addtemplate .form-control {
  font-size: 14px;
}

#addtemplate .survey_section,
#edittemplate .survey_section {
  /*max-height: calc(100vh - 450px);*/
  max-height: 600px;
  overflow-y: auto;
}

#addtemplate .survey_section .form-control:disabled,
#edittemplate .survey_section .form-control:disabled {
  background-color: #fff;
  border: 1px solid #ced4da;
}

.svd_page>.title_editable .svda-title-editor-start .svda-title-editor-placeholder {
  display: none;
}

.svd_page>.description_editable .svda-title-editor-start .svda-title-editor-placeholder {
  display: none;
}

/* .survey_section .svd_container .svd-primary-icon .svd-svg-icon{
  display: none;
} */
.svda-content {
  display: none;
}

.svda-select-items-title {
  display: none;
}

.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor {
  padding: 0 !important;
}

.survey_section .svd_container .svd-tabs {
  padding-left: 0;
}

.survey_section .svd_container .btn-primary {
  background-color: #A9B533;
  border-color: #A9B533;
  outline: none;
}

.survey_section .svd_container .svd-tab.active>.svd-tab-text {
  color: #05a2dd;
}

.survey_section .svd_container .svd-main-color {
  color: #05a2dd;
}

.survey_section .svd_container .svd-primary-icon .svd-svg-icon {
  fill: #05a2dd;
}

#icon-inplaceplus {
  display: none;
}

.sv_technical .svda-add-new-item {
  display: none;
}

.survey_section .svd_toolbox_item:hover {
  background-color: #05a2dd;
  color: #fff;
}

.survey_section .svd_container .svd_custom_select:before {
  background-color: #05a2dd;
}

.survey_section .svd_container a:hover,
.survey_section .svd_container a:focus {
  color: #05a2dd;
  outline-color: #05a2dd;
}

.survey_section .svd-survey-placeholder-root .svd-survey-placeholder .svd-empty-message-container .svd-empty-message {
  color: #05a2dd;
}

.survey_section .sv_main input[type="button"],
.survey_section .sv_main button {
  background-color: #05a2dd;
  color: #fff;
}

.survey_section .svd_container .svd-main-background-color {
  background-color: #05a2dd;
}

.survey_section .svd_container .h-card {
  background-color: #cedff5 !important;
}

.svd_container .svd_content .svd-logic-tab .btn {
  margin: 0 5px;
}

.survey_section .svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .panel-body .svd_question,
.survey_section .svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .card-block .svd_question {
  background: #fff;
  border-radius: 7px;
  width: 100% !important;
  flex: 1 1 100% !important;
  border-left: 4px solid #94c795;
}

.survey_section .svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .card-block .undefined.svd_question {
  border-left: 4px solid #a794c7;
}

.survey_section .form-control {
  height: 34px !important;
  margin-bottom: 10px;
}

h4.title_editable span:empty,
.small.description_editable span:empty {
  display: none;
}

.survey_section .svd_container .svd_toolbox .panel-info.panel {
  background: white;
}

.survey_section .svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor {
  background: transparent;
}

.survey_section .svd_container .svd-tab-text {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: normal;
}

.survey_section .svda-add-custom-item {
  color: #05a2dd;
}

.survey_section .svd_q_selected .svda-add-new-item {
  padding-left: 5px;
}

.survey_section .svd-simulator-main .panel-body {
  margin-top: 0 !important;

}

.survey_section .svd-simulator-main .sv_qstn {
  border: 1px solid #ddd;
  border-left: 4px solid #94c795;
  margin: 5px 0;
  border-radius: 7px;
}

.survey_section .svd-simulator-main .sv_qstn.undefined {
  border: 1px solid #ddd;
  border-left: 4px solid #a794c7;
  margin: 5px 0;
  border-radius: 7px;
  border-radius: 7px;
  width: 100% !important;
  flex: 1 1 100% !important;
}

.survey_section .svd-simulator-main .sv_row>div:first-child {
  width: 100% !important;
  flex: 1 1 100% !important;
}

.survey_section .svd-simulator-main .sv_row>div:last-child {
  width: 100% !important;
  flex: 1 1 100% !important;
}

.survey_section .svd-property-editor-survey .form-control {
  font-size: 14px !important;
  line-height: normal !important;
}

.survey_section .svd_container .svd_content .svd_custom_select {
  font-size: 14px;
  font-weight: normal;
}

.survey_section .svd_container .svd_content .svd-logic-tab .svd-propertyeditor-condition {
  margin-bottom: 20px;
}

.survey_section .svd_container .svd_content .svd-logic-tab .btn {
  /* border-radius: 20px;
  font-size: 14px;
  font-weight: normal;
  padding:10px 30px; */
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: fit-content;
  background-color: var(--primary-colour);
  color: #FFF !important;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--primary-colour);
  font-weight: 500;
}

.survey_section .svd_container .svd_content .svd-logic-tab .btn:hover {
  background: #879129;
  color: #FFF;
}

.survey_section .svd_container .svd_content .svd-logic-tab .btn:focus {
  outline: 0;
  color: #fff;
}

.svd-designer-container--right-side .svd-icon-container--right-close {
  display: none !important;
}

.svd-vertical-container__cell .svd-designer-container--right-side .svd-designer-container__header {
  display: none;
}

.svd-vertical-container__cell .svd_toolbar .svd-toolbar-dropdown {
  display: none;
}

.svd-propertyeditor-condition .svd-expression-content div:first-child .btn {
  display: none;
}

.svd-logic-tab__item+p+div {
  width: 162px !important;
}

.survey_section .svd-simulator-main .panel-body .panel-footer {
  padding: 0;
  border: 0;
}

.survey_section .svd-simulator-main .panel-body .panel-footer .btn {
  display: none;
}

/* Add block*/
#addblock textarea,
#editblock textarea {
  resize: none;
}

.no-resize {
  resize: none;
}

.action_icon,
.edit_btn {
  cursor: pointer;
}

.svd-propertyeditor-condition-wide-table+.form-inline .svd-logic-add-condition-button {
  display: none;
}

input.form-control.btn.sv-btn.btn-primary.svd-logic-button.svd-logic-save-button {
  display: none;
}

div#sq_132i {
  display: none;
}

.svd-logic-tab__item .svd_custom_select select option:first-child {
  display: none;
}

.tempcreate_sec.max-height-removal {
  max-height: inherit;
}

.approval_main {
  height: calc(100vh - 63px);
}

span.card {
  display: inline-block;
}

/* div title + p span:not(.card):first-child{
    display: block;
} */

.block_preview_panel.panel-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  padding: 10px;
  max-height: 100vh;
  min-height: 100%;
}

.template_preview_panel.panel-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  padding: 10px;
  max-height: 100vh;
  min-height: 100%;
}

.block_preview_panel.panel-fullscreen .preview_body {
  min-height: calc(100vh - 86px) !important;
  max-height: calc(100vh - 86px) !important;
}

.preview_header {
  position: relative;
}

.preview_header {
  padding: 15px;
  background-color: #dce6ef;
  height: 64px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.preview_header h5 {
  color: #426694;
  font-weight: 600;
  text-align: left;
  display: inline-block;
  font-size: 14px;
  margin: 0;
}

#panel-fullscreen {
  position: relative;
  right: 10px;
  float: right;
}

#panel-fullscreen img {
  width: 16px;
}

.glyphicon-resize-small .expand-icon {
  display: none;
}

.glyphicon-resize-full .compress-icon {
  display: none;
}

.panel-fullscreen .sv_qstn {
  min-width: 100% !important;
}

.template_preview_panel.panel-fullscreen .preview_body {
  max-height: inherit;
  overflow: visible;
}

#viewtemplate .modal-dialog.modal-lg {
  max-width: 1280px;
}

.svd_container .svd_content .svd-logic-tab .svd-danger-icon .svd-svg-icon {
  fill: var(--error-color, #05a2dd);
  fill: #05a2dd;
}

.svd_container .svd_content {
  border-radius: 0;
}

.docpreview .preview_body {
  max-height: inherit;
}

#pagetemplate .model-action {
  padding: 16px;
  padding-bottom: 0px;
}

.letterhistory .wordscheck {
  word-break: break-word;
}

.question_align .sv_row .sv_qstn {
  width: 100% !important;
  flex: inherit !important;
}

.letter_preview_panel.max-height-removal {
  max-height: calc(100vh - 100px);
}

.letter_preview_panel.drop_down_sec .preview_body {
  max-height: inherit;
  overflow: inherit;
}

.template_preview_panel.docpreview.panel-fullscreen iframe {
  height: 87vh !important;
}

.template_preview_panel.temp_new_align .preview_body {
  overflow-y: auto;
  max-height: calc(69vh - 44px);
  min-height: calc(69vh - 44px);
}

.full_height_div {
  max-height: calc(69vh - 44px);
  min-height: calc(69vh - 44px);
}

/* trIAL */
.svd-designer-container--right-side {

  width: 23.06%;
  max-width: 23.06%;
  flex-basis: 23.06%;
  overflow: auto;
}

.svd-designer-container {
  overflow: auto !important;
}

/* .svd_container.svd-light-bg-color.sv_bootstrap_css{
height: 500px !important;
} */
.svd_container {
  height: 500px !important;
}

.svd-vertical-container__cell-content-holder,
.svd-vertical-container__cell-content {
  overflow: auto !important;
}

/* Fields */
.errorcolor {
  color: #ff3434 !important;
  font-size: 100%;
}

#add-fields .modal-dialog textarea {
  resize: none;
}

/* #addtemplate{
height: calc(100vh - 110px);
} */
/* -------------------------------------------------------------------------------------- */
.errorcolor {
  color: #ff3434 !important;
  font-size: 100%;
}

/* button */
.add-btn {
  display: inline-flex;
  align-items: center;
  height: 40px;
  background-color: #057CD1;
  color: #FFF;
  font-size: 14px;
  padding: 0.375rem 0.75rem 0.375rem 1rem;
  border-radius: 4px;
  border: 1px solid #057CD1;
  font-weight: 500;
}

.btn.add-btn:not(:disabled):hover {
  background-color: #057CD1;
  border: 1px solid #057CD1;
  color: #FFF;
}

/* Template screen  */
.template .block-list {
  color: #5E6D83;
  min-height: 400px;
}

.block-list ul li {
  border-bottom: 1px solid #F8F8F8;
}

.upload-attachment .pdf-upload {
  min-height: 250px;
  height: 250px;
}

/* pdf upload link  */
#upload-btn {
  display: block;
}

#attachment1 {
  display: none;
}

.attachment1cls {
  display: block;
}

/* bulk letter upload csv */
.upload-files .drag-drop {
  min-height: 300px;
  background-color: #F0F2F4;
  border: 1px dashed #c3c3c3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-progress-bar {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20);
}

.progress-bar {
  background-color: #268AD3;

}

/* Add & Edit Questions */
#add-questions textarea,
#edit-questions textarea {
  resize: none;
}

.edit_text {
  /* background: rgba(66,102,148, 0.12); */
  padding: 16px;
  position: relative;
}

.edit_text.editable {
  /* background: rgba(66,102,148, 0.12); */
  background-color: #d5e5f4;
}

.edit_text p {
  margin-bottom: 0;
}

.preview_body span.content_attributes {
  /* background: #426694; */
  background-color: #5b9bd5;
  right: 0;
  top: 0px;
  border-radius: 0;
  font-size: 12px;
  padding: 2px 7px;
  left: auto;
  color: white;
}

.preview_body span.content_attributes em {
  margin-left: 2px;
}

.preview_body span.content_attributes_noteditable {
  /* background: #aa0513cc; */
  background: #902f38;
  right: 0;
  top: 0px;
  border-radius: 0;
  font-size: 12px;
  padding: 2px 7px;
  left: auto;
  color: white;
}

.sv_q_title {
  font-size: 16px;
}

/* sv_q sv_qstn */
.para .sv_q.sv_qstn,
.preview_body .sv_q.sv_qstn {
  margin-bottom: 24px;
}

.sv_q.sv_qstn.undefined {
  padding: 0px;
}

.preview_body .sv_row {
  background-color: transparent !important;
  border: none;

}

/* tool tip */
.p-tooltip .p-tooltip-text {
  font-size: 12px !important;
}

/* dropdown */
.multiselect-dropdown .dropdown-list {
  margin: 0px;
}

.multiselect-dropdown .dropdown-btn {
  /* height: 35px; */
  min-height: 35px;
}

/* Preview body scroll */
.preview_body_scroll {
  max-height: 300px;
  overflow-y: auto;
}

/* progress bar bg-style */
.p-progressbar-value.p-progressbar-value-animate.ng-star-inserted {
  background-color: #143a4d;
}

.p-progressbar-determinate .p-progressbar-label {
  color: #A9B533;
}

/* a.cke_combo_button {
  display: none !important;
} */
.cke_button__justifyleft_icon {
  display: none !important;
}

.cke_button__justifycenter_icon {
  display: none !important;
}

.cke_button__justifyright {
  display: none !important;
}

.cke_notifications_area {
  display: none !important;
}

#Edit7216ModalDialog,
#CreateEdit7216ModalDialog {
  max-width: 60%;
  width: 60%;
}

/* client and template */
.Creation-Mode:not(.active):hover,
.template:not(.active):hover,
.Creation-Mode.active,
.template.active {
  background-color: #bcddf573;
}

.Creation-Mode.active,
.template.active {
  background-color: #bcddf573;
  border: 1.5px solid #143A4D !important;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.10);
}

/* prime p multiselect */
/* .table-multiselect {} */

.pi-chevron-down:before {
  /* content: "\25BC"; */
  font-size: 11px;
}

.table-multiselect .p-multiselect {
  height: 36px;
}

.table-multiselect .p-multiselect .p-multiselect-label {
  padding-block: 6px;
  font-size: 14.5px;
}

.p-multiselect-header .p-checkbox {
  display: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}

.p-multiselect-item.p-highlight {
  font-weight: 500;
}

.p-multiselect-item .pi-check {
  position: relative;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333;
  background: #f5faff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #333;
  /* background: #ebf5ff; */
  background: #FFF;
}

/* not recommended to do this */
.Dropdown-show-over-modal {
  z-index: 9999 !important;
}

.p-multiselect-item .pi-check:before {
  /* content: "\e909"; */
  content: " ";
  /* background: var(--primary-colour); */
  /* background: red; */
  background: transparent;
  background-image: url('./assets/images/tick.svg');
  background-position: center;
  width: 18px;
  height: 18px;
}

/* table */
.new-implement-attachment {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.new-implement-attachment th,
.new-implement-attachment td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.new-implement-attachment th {
  background-color: #f2f2f2;
}

.new-implement-attachment .actions {
  display: flex;
  justify-content: space-between;
}

/* notes */
p.note {
  display: flex;
  align-items: center;
}

p.note .fa-triangle-exclamation {
  font-size: 24px;
}

.table-header-w-200 {
  width: 200px;
}

.table-cell-ellipse {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.last-question-color {
  color: #6c757d;
}

#engage-InformationEmail .modal-dialog {
  max-width: 60%;
  /* Increase width */
}

#EmailInfoContent {
  max-height: 90vh;
  /* Increase height */
  overflow-y: auto;
}

.email-info textarea {
  width: 100%;
  height: auto;
  /* Make sure it auto-fits content */
  /* overflow: hidden; */
  resize: none;
  /* Prevent manual resizing */
}

#emailInfoButton {
  background-color: #F0F2F3;
}

#emailRemainderModal .modal-dialog {
  max-width: 48%;
}

.differentiatesitecolor {
  text-align: center;
  color: red;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 1px 1px #000;
}

.reminder-form .form-control {
  margin-top: 5px;
  padding: 5px;
}

.reminder-settings .Reminder-dropdown {
  flex-basis: 120px;
}

.reminder-form .btn[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}